import { RouterProvider } from "react-router-dom";
import { Providers } from "./providers";
import { router } from "./router/routes";
import { Toaster } from "sonner";
import { rehydrateAll } from "./store/indexDB/utils";

rehydrateAll();

export const App = () => {
  return (
    <Providers>
      <div className="App theme1">
        <div id="portal" />
        <Toaster richColors position="top-center" />
        <RouterProvider router={router} />
      </div>
    </Providers>
  );
};
