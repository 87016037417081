import { ReactiveVar } from "@apollo/client";
import { indexDBInstance } from "./index";
import { FAVORITE_SKUS_REHYDRATE_INFO } from "./skus.db";

const rehydrate = async <T>(key: string, reactiveVar: ReactiveVar<T>) => {
  const data: T = await indexDBInstance.getItem(key);
  if (data) {
    reactiveVar(data);
  }
};

export const rehydrateAll = () => {
  [FAVORITE_SKUS_REHYDRATE_INFO].forEach((i) => {
    rehydrate(i.key, i.reactiveVar);
  });
};
